const IconLink = props => {
  return (
    <svg
      width='22'
      height='12'
      viewBox='0 0 22 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10.2184 11.7438H5.79134C4.35037 11.7438 3.12207 11.2157 2.10645 10.1594C1.09082 9.10317 0.583008 7.82574 0.583008 6.32713C0.583008 4.82852 1.09082 3.55109 2.10645 2.49484C3.12207 1.43859 4.35037 0.910461 5.79134 0.910461H10.2184V2.53546H5.79134C4.77861 2.53546 3.91779 2.90348 3.20887 3.63951C2.49996 4.37553 2.14551 5.26928 2.14551 6.32076C2.14551 7.37223 2.49996 8.2681 3.20887 9.00838C3.91779 9.74866 4.77861 10.1188 5.79134 10.1188H10.2184V11.7438ZM6.96322 7.13963V5.51463H15.0361V7.13963H6.96322ZM11.7809 11.7438V10.1188H16.208C17.2207 10.1188 18.0816 9.75078 18.7905 9.01474C19.4994 8.27873 19.8538 7.38498 19.8538 6.33349C19.8538 5.28203 19.4994 4.38616 18.7905 3.64588C18.0816 2.9056 17.2207 2.53546 16.208 2.53546H11.7809V0.910461H16.208C17.649 0.910461 18.8773 1.43859 19.8929 2.49484C20.9085 3.55109 21.4163 4.82852 21.4163 6.32713C21.4163 7.82574 20.9085 9.10317 19.8929 10.1594C18.8773 11.2157 17.649 11.7438 16.208 11.7438H11.7809Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconLink
