import { getChildPages } from '@lib/api-services/cmsService'

const SuburbApiDataFromName = async (suburbName) => {
  const suburbResponse = await getChildPages(
    'suburbs.SuburbPage',
    null,
    null,
    null,
    { title: suburbName },
    [
      'title',
      'map_marker',
      'state',
      'postcode',
      'lifestyle_categories',
      'property_stats',
      'path',
      'featured_image',
    ]
  )

  const suburbData =
    suburbResponse.items &&
    suburbResponse.items.length > 0 &&
    suburbResponse.items[0]
  if (suburbData) {
    let suburbProfileProps = {
      suburb_name: suburbData.title,
      suburb_address: `${suburbData.title}, ${suburbData.state}`,
      location: suburbData.map_marker,
      infoColumnOne: [], // Was Corelogic data. Contract issues
      infoColumnTwo: [], // Lifestyle and property stats,
      ...suburbData,
    }
    let lifestyleCats = []
    suburbData.lifestyle_categories &&
      suburbData.lifestyle_categories.length > 0 &&
      suburbData.lifestyle_categories.map((cat) => {
        lifestyleCats.push({
          link: cat.meta.path,
          value: cat.category.title,
        })
      })
    suburbProfileProps.infoColumnTwo.push({
      key: 'Lifestyle',
      values: lifestyleCats,
    })

    if (suburbData.property_stats) {
      // For Sale count
      suburbData.property_stats.for_sale
        ? suburbProfileProps.infoColumnTwo.push({
            key: 'Properties for Sale',
            value: suburbData.property_stats.for_sale,
            link: `/for-sale/properties-sale/?address_suburb=${suburbData.title}`,
          })
        : null

      // Sold count
      suburbData.property_stats.for_sale
        ? suburbProfileProps.infoColumnTwo.push({
            key: 'Sold Properties',
            value: suburbData.property_stats.sold,
            link: `/sold-properties/?address_suburb=${suburbData.title}`,
          })
        : null

      // For Rent
      suburbData.property_stats.for_sale
        ? suburbProfileProps.infoColumnTwo.push({
            key: 'Properties for Rent',
            value: suburbData.property_stats.for_rent,
            link: `/rental-properties/properties-rent/?address_suburb=${suburbData.title}`,
          })
        : null
    }
    suburbProfileProps.suburb_profile_link = {
      label: 'suburb profile',
      link: suburbData.meta?.path,
    }
    return suburbProfileProps
  }
}

export default SuburbApiDataFromName
