import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'

const CarouselButtonStyle = css`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.white};
  color: ${COLORS.secondary};
  cursor: pointer;
  position: absolute;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.25s ease-in;

  &.slick-disabled {
    display: none;
  }

  &:before {
    display: none;
  }

  svg {
    position: relative;
  }

  &:hover {
    background-color: ${COLORS.secondary};
    color: ${COLORS.white};
  }
`

export const GalleryItem = styled.div`
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.navi};

  img {
    height: 100%;
    object-fit: contain;
  }
`

export const GalleryMain = styled.div`
  flex: 1;
  background-color: #000;
  width: calc(100% - 352px);
  height: 100%;

  .slick-slider,
  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-slider .slick-next {
    right: 10px;

    svg {
      right: -1px;
    }
  }

  .slick-slider .slick-prev {
    left: 10px;

    svg {
      left: -1px;
    }
  }

  .slick-next,
  .slick-prev {
    ${CarouselButtonStyle};
  }

  .slick-slide > div {
    height: 100%;
  }

  ${({ fullwidth }) =>
    fullwidth &&
    css`
      width: 100%;

      ${GalleryItem} img {
        object-fit: cover;
      }
    `}

  @media ${DEVICES.mobile} {
    width: 100%;
  }
`

export const CarouselWrapper = styled.div`
  .slick-slide > div {
    margin-right: 24px;
  }
`

export const CarouselNext = styled.div``
export const CarouselPev = styled.div``
