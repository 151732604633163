const IconBathBig = (props) => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18.0683 19.9847H9.92393C5.94604 19.9847 2.72168 16.7604 2.72168 12.7825V12.6201H25.2706V12.7825C25.2697 16.7604 22.0453 19.9847 18.0683 19.9847Z'
        stroke='currentColor'
        strokeWidth='1.12609'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M8.93031 10.2318C8.93031 9.55892 8.3851 9.01465 7.7132 9.01465C7.04037 9.01465 6.49609 9.55986 6.49609 10.2318V12.8518'
        stroke='currentColor'
        strokeWidth='1.12609'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M20.7354 12.9424H15.3809V15.4057H20.7354V12.9424Z'
        stroke='currentColor'
        strokeWidth='0.750724'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconBathBig
