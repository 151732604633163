const IconBedBig = (props) => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M24.6784 20.8603V15.8916H3.23535V20.8603'
        stroke='currentColor'
        strokeWidth='1.06682'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M23.4765 16.2512V11.3608C23.4765 9.95612 22.3492 8.81641 20.9579 8.81641H7.0635C5.67308 8.81641 4.54492 9.95524 4.54492 11.3608V16.2512'
        stroke='currentColor'
        strokeWidth='1.06682'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M11.9669 14.0907H7.61429C7.03643 14.0907 6.56348 13.6177 6.56348 13.0398V13.0176C6.56348 12.4398 7.03643 11.9668 7.61429 11.9668H11.9669C12.5448 11.9668 13.0177 12.4398 13.0177 13.0176V13.0398C13.0177 13.6177 12.5448 14.0907 11.9669 14.0907Z'
        stroke='currentColor'
        strokeWidth='0.711212'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.3243 14.0907H15.9717C15.3939 14.0907 14.9209 13.6177 14.9209 13.0398V13.0176C14.9209 12.4398 15.3939 11.9668 15.9717 11.9668H20.3243C20.9022 11.9668 21.3751 12.4398 21.3751 13.0176V13.0398C21.3751 13.6177 20.9022 14.0907 20.3243 14.0907Z'
        stroke='currentColor'
        strokeWidth='0.711212'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconBedBig
