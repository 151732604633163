import { useEnquiryFormContext } from '@context/EnquiryFormContext'
import Button from '@global/Button'
import { LISTING_STATUS } from '@constants/property'
import MakeOfferButton from './MakeOfferButton'
import * as S from './ContactNavActions.styled'

const ContactNavActions = ({ listing, mobile }) => {
  let irePropertyID = ''
  if (
    listing.integration_for_2apply === 'inbound' &&
    listing.external_id !== '' &&
    listing.external_id !== null
  ) {
    irePropertyID = listing.external_id
  } else if (listing.integration_for_2apply === 'ire') {
    irePropertyID = listing.ireID
  }

  const { setShowEnquiryForm } = useEnquiryFormContext()

  const checkApplyNowData = () => {
    let url = ''
    let book_url = ''
    let listing_address = ''
    if (
      listing.listingType === 'lease' &&
      listing.status !== 'leased' &&
      listing.office_details &&
      listing.office_details.office
    ) {
      if (listing.office_details.office.snug_integration) {
        let street = listing.address_street.split(' ')
        let street_type = street.pop()
        street = street.join(' ')
        if (listing.office_details.office.snug_team_ID != '') {
          let address_information = `?add_unitnum=${listing.address_subNumber}&add_streetnum=${listing.address_streetNumber}&add_streetname=${street}&add_streettype=${street_type}&add_suburb=${listing.address_suburb}&add_state=${listing.address_state}&add_postcode=${listing.address_postcode}`
          let base_url = `https://api.snug.com/v1/partner/handoff/team/${listing.office_details.office.snug_team_ID}/listing/${listing.id}`
          book_url = `${base_url}${address_information}`
          url = `${base_url}/apply${address_information}`
        }
      } else if (
        listing.office_details.office.ignite_apply_enabled &&
        listing.office_details.office.ignite_agency_id
      ) {
        let ignite_email = listing.office_details.office.ignite_email
          ? listing.office_details.office.ignite_email
          : 'contact@barryplant.com.au'
        if (listing.address_subNumber) {
          listing_address = `${listing.address_subNumber}/${listing.address_streetNumber}%20${listing.address_street}%20${listing.address_suburb}%20${listing.address_state}%20${listing.address_postcode}&email=${ignite_email}`
        } else {
          listing_address = `${listing.address_streetNumber}%20${listing.address_street}%20${listing.address_suburb}%20${listing.address_state}%20${listing.address_postcode}&email=${ignite_email}`
        }
        url = `https://www.realestate.com.au/rent/applications/rea-listing/lookup?agencyId=${listing.office_details.office.ignite_agency_id}&address=${listing_address}&email=${ignite_email}`
      } else if (
        listing.office_details.office.our_property_integration &&
        listing.office_details.office.our_property_url
      ) {
        url = listing.office_details.office.our_property_url
        book_url = url
      } else if (listing.office_details.office.sorted_integration) {
        url = `https://apply.sortedservices.com/#/properties?agentId=AU_BRYP&id=${listing.external_id}&type=a&rent=${listing.rent}`
      } else if (
        irePropertyID !== '' &&
        listing.office_details.office.inspect_re_id_rental_application !== ''
      ) {
        url = `https://2apply.com.au/Form?agentID=${listing.office_details.office.inspect_re_id_rental_application}&uniqueID=${irePropertyID}`
      } else if (listing.office_details.office.rental_application_url !== '') {
        url = listing.office_details.office.rental_application_url
      }
      return url !== '' ? (
        <span>
          {book_url ? (
            <a href={book_url} target='_blank'>
              <Button color='secondary' border block>
                Book an inspection
              </Button>
            </a>
          ) : null}
          <a href={url} target='_blank'>
            <Button color='secondary' border block>
              Apply now
            </Button>
          </a>
        </span>
      ) : null
    } else return null
  }

  return (
    <S.AgentActions>
      <Button color='secondary' block onClick={() => setShowEnquiryForm(true)}>
        Enquire about this property
      </Button>
      {!mobile && (
        <MakeOfferButton listing={listing} id='make-offer-container' />
      )}
      {checkApplyNowData()}
      {listing.status !== LISTING_STATUS.SOLD &&
      listing.status !== LISTING_STATUS.LEASED &&
      irePropertyID != '' &&
      listing.office_details &&
      listing.office_details.office &&
      listing.office_details.office.snug_integration == '' &&
      listing.office_details.office.inspect_re_id != '' ? (
        <a
          href={`https://book.inspectrealestate.com.au/RegisterNew.aspx?agentID=${listing.office_details.office.inspect_re_id}&uniqueID=${irePropertyID}`}
          target='_blank'
          passHref
        >
          <Button color='secondary' border block>
            Book an inspection
          </Button>
        </a>
      ) : null}
    </S.AgentActions>
  )
}

export default ContactNavActions
