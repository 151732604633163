import { UrlBuilder } from '../helpers'
import { apiCall } from '../apiServerHelper'
import { apis } from '../apiUrls'
import { itemShowPerPage } from '../variables'

export const getProperties = async (params, token) => {
  let url = apis.PROPERTIES_API_BASE
  if (params) url = UrlBuilder(url, params)
  if (token) return await apiCall(url, undefined, 'get', false, token)
  else return await apiCall(url)
}

export const getMapProperties = async (params, token) => {
  const { ['per_page']: per_page, ...searchParams } = params
  const url = UrlBuilder(apis.PROPERTIES_API_BASE, {
    per_page: itemShowPerPage.PropertyMapSearch,
    ...searchParams
  })
  if (token)
    return await apiCall(
      `${url}&fields=location,id&)`,
      undefined,
      'get',
      false,
      token
    )
  else return await apiCall(`${url}&fields=location,id&)`)
}

export const getProperty = async (id, token, params) => {
  let url = `${apis.PROPERTIES_API_BASE}${id}/`
  if (params) url = UrlBuilder(url, params)
  if (token) return await apiCall(url, undefined, 'get', false, token)
  else return await apiCall(url)
}

export const getInspectionProperties = async (params, token) => {
  let url = UrlBuilder(apis.PROPERTIES_INSPECTION_API, params)
  if (token) return await apiCall(url, undefined, 'get', false, token)
  else return await apiCall(url)
}

export const getAuctionProperties = async (params, token) => {
  let url = UrlBuilder(apis.PROPERTIES_AUCTION_API, params)
  if (token) return await apiCall(url, undefined, 'get', false, token)
  else return await apiCall(url)
}
