import styled, { css } from 'styled-components'
import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, textSM } from '@styles/fonts'

export const ContactNav = styled.div`
  position: sticky;
  top: 20px;
  padding-top: 15px;

  @media ${DEVICES.tabletMini} {
    position: relative;
    top: auto;
    display: none;
  }

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;

      @media ${DEVICES.tabletMini} {
        display: block;
        position: relative;
        padding-bottom: 55px;
      }

      @media ${DEVICES.mobile} {
        padding-bottom: 40px;
      }
    `}
`

export const ContactGroup = styled.div`
  padding-bottom: 36px;

  ${({ office }) =>
    office &&
    css`
      padding-bottom: 40px;
    `}

  @media ${DEVICES.mobile} {
    padding-bottom: 40px;
  }
`

export const ContactGroupTitle = styled.p`
  ${textSM};
  color: ${COLORS.secondary};
  text-transform: uppercase;
  font-family: ${FONTS.bold};
  letter-spacing: 1px;
  margin-bottom: 12px;
`

export const ContactGroupList = styled.div`
  margin-bottom: -12px;
`
