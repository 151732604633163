import Button from '@global/Button'
import IconFile from '@global/Icons/File'
import { adfenix } from '@lib/adfenixHelper'

const StatementOfInformation = ({ documents }) => {
  const doc = documents?.find(
    (it) =>
      it.name?.toLowerCase() === 'statement of information' ||
      it.name?.toLowerCase() === 'statementofinformation'
  )

  return doc ? (
    <a
      href={doc.file}
      target='_blank'
      onClick={() => adfenix.downloadinfoclick()}
    >
      <Button border startIcon={<IconFile />} color='primary' size='sm'>
        Statement of information
      </Button>
    </a>
  ) : null
}

export default StatementOfInformation
