import styled from 'styled-components'
import { DEVICES } from '@constants/global'
import { FONTS } from '@styles/fonts'

export const Detail = styled.div`
  margin-bottom: 56px;

  @media ${DEVICES.tablet} {
    margin-bottom: 40px;
  }
`

export const DetailHeading = styled.h3`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: ${FONTS.bold};
`

export const PropertyDetails = styled.div`
  padding-right: 86px;

  @media ${DEVICES.tabletMini} {
    padding-right: 0;
  }
`
