const IconCamera = (props) => {
  return (
    <svg
      width='18'
      height='16'
      viewBox='0 0 18 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1.5843 15.6667C1.2363 15.6667 0.931797 15.5362 0.670797 15.2752C0.409797 15.0142 0.279297 14.7097 0.279297 14.3617V3.20407C0.279297 2.87057 0.409797 2.5697 0.670797 2.30145C0.931797 2.0332 1.2363 1.89907 1.5843 1.89907H4.78155L6.3693 0.00683594H11.5893L13.177 1.89907H16.3743C16.7078 1.89907 17.0087 2.0332 17.2769 2.30145C17.5452 2.5697 17.6793 2.87057 17.6793 3.20407V14.3617C17.6793 14.7097 17.5452 15.0142 17.2769 15.2752C17.0087 15.5362 16.7078 15.6667 16.3743 15.6667H1.5843ZM16.3743 14.3617V3.20407H12.568L10.9803 1.31183H6.9783L5.39055 3.20407H1.5843V14.3617H16.3743Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.7688 8.49959C12.7688 10.5413 11.1136 12.1964 9.07188 12.1964C7.03015 12.1964 5.375 10.5413 5.375 8.49959C5.375 6.45787 7.03015 4.80273 9.07188 4.80273C11.1136 4.80273 12.7688 6.45787 12.7688 8.49959ZM9.07188 10.9975C10.4514 10.9975 11.5698 9.87912 11.5698 8.49959C11.5698 7.12005 10.4514 6.00171 9.07188 6.00171C7.69233 6.00171 6.57399 7.12005 6.57399 8.49959C6.57399 9.87912 7.69233 10.9975 9.07188 10.9975Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconCamera
