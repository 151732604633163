export const events = {
  bookappraisal: 'bookappraisal',
  bookshowing: 'bookshowing',
  contactrequestclick: 'contactrequestclick',
  contactrequestsubmit: 'contactrequestsubmit',
  downloadinfoclick: 'downloadinfoclick',
  downloadinfosubmit: 'downloadinfosubmit'
}
export const adfenix = {
  tracking: event => {
    if (window.adfenix) {
      window.adfenix.track(event)
    }
  },
  bookappraisal: () => {
    if (window.adfenix) {
      window.adfenix.track(events.bookappraisal)
    }
  },
  bookshowing: () => {
    if (window.adfenix) {
      window.adfenix.track(events.bookshowing)
    }
  },
  contactrequestclick: () => {
    if (window.adfenix) {
      window.adfenix.track(events.contactrequestclick)
    }
  },
  contactrequestsubmit: () => {
    if (window.adfenix) {
      window.adfenix.track(events.contactrequestsubmit)
    }
  },
  downloadinfoclick: () => {
    if (window.adfenix) {
      window.adfenix.track(events.downloadinfoclick)
    }
  },
  downloadinfosubmit: () => {
    if (window.adfenix) {
      window.adfenix.track(events.downloadinfosubmit)
    }
  }
}
