import { useLayoutEffect, useRef, useState } from 'react'
import * as S from './MakeOfferButton.styled'
import useInterval from '@hooks/useInterval'
import { PropertyMobileNavItem } from './MakeOfferButton.styled'
import * as G from '@styles/MobileNav.styled'

const MakeOfferButton = ({ listing, id, isMobile }) => {
  const [isHaveMakeOfferButton, setHaveMakeOfferButton] = useState(false)
  const containerRef = useRef(null)

  useLayoutEffect(() => {
    if (typeof window !== 'undefined' && window.OfferKit) {
      OfferKit.exec('mount', {
        listingId: `${listing.id}`,
        container: `#${id}`,
      })
    }

    return () => {
      if (typeof window !== 'undefined' && window.OfferKit) {
        OfferKit.exec('unmount')
      }
    }
  }, [listing.id])

  useInterval(
    () => {
      const offerButton = containerRef.current?.querySelector(
        '#OfferKit_make-an-offer'
      )
      if (offerButton) {
        if (isMobile) {
          containerRef.current.classList.add('active')
          offerButton.innerHTML =
            '<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.3334 17.6364C14.5508 17.6364 17.9697 14.2174 17.9697 10C17.9697 5.78255 14.5508 2.36364 10.3334 2.36364C6.11593 2.36364 2.69701 5.78255 2.69701 10C2.69701 14.2174 6.11593 17.6364 10.3334 17.6364ZM10.3334 18.75C15.1659 18.75 19.0834 14.8325 19.0834 10C19.0834 5.16751 15.1659 1.25 10.3334 1.25C5.50088 1.25 1.58337 5.16751 1.58337 10C1.58337 14.8325 5.50088 18.75 10.3334 18.75Z" fill="currentColor"></path><path d="M7.48103 13.8276C7.36725 13.7138 7.25348 13.6142 7.40992 13.3724L7.82237 12.7751C7.93614 12.6187 8.10681 12.5476 8.26326 12.6756C8.33437 12.7467 9.1877 13.4578 10.1548 13.6427V10.4284C8.91748 9.94489 7.40992 9.13422 7.40992 7.44178C7.40992 5.92 8.53348 4.85333 10.169 4.72533V4.27022C10.169 4.11378 10.297 4 10.4393 4H10.5815C10.7237 4 10.8233 4.11378 10.8233 4.27022V4.73956C12.0748 4.83911 13.0277 5.46489 13.1557 5.55022C13.2695 5.62133 13.369 5.82044 13.241 6.03378C12.985 6.40356 12.729 6.944 12.3877 6.71645C12.3024 6.67378 11.5486 6.19022 10.8233 6.01956V9.14844C12.3024 9.77422 13.7104 10.5564 13.7104 12.2631C13.7104 13.7422 12.5299 14.8089 10.8233 14.9369V15.744C10.8233 15.9147 10.681 16 10.5246 16C10.3682 16 10.1548 15.9573 10.1548 15.744V14.9369C8.64726 14.8231 7.69437 14.0267 7.48103 13.8276ZM10.1548 8.864V5.99111C9.1877 6.09067 8.78948 6.74489 8.78948 7.22844C8.78948 7.95378 9.27304 8.43733 10.1548 8.864ZM10.8233 13.6996C11.6055 13.6 12.217 13.1591 12.217 12.3911C12.217 11.7084 11.7335 11.2107 10.8233 10.7413V13.6996Z" fill="currentColor"></path></svg>Make Offer'
        }
        setHaveMakeOfferButton(true)
      }
    },
    isHaveMakeOfferButton ? null : 1000
  )

  return (
    <S.MakeOfferButton id={id} ref={containerRef} active/>
  )
}

export default MakeOfferButton
