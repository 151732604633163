import { Link } from '@routes'
import IconAngleRight from '@icons/AngleRight'
import { titleCase } from '@lib/helpers'
import * as S from './Breadcrumbs.styled'

const Breadcrumbs = ({ breadcrumbs = [] }) => {
  return (
    <S.Breadcrumbs>
      {breadcrumbs.map((it, key) =>
        key === breadcrumbs.length - 1 ? (
          <span key={`breadcrumb-${key}`}>{it.label}</span>
        ) : (
          <Link route={it.url} passHref key={`breadcrumb-${key}`}>
            {titleCase(it.label)} <IconAngleRight />
          </Link>
        )
      )}
    </S.Breadcrumbs>
  )
}

export default Breadcrumbs
