import Button from '@global/Button'
import IconFile from '@global/Icons/File'
import IconLink from '@global/Icons/Link'
import { Detail, DetailHeading } from '../PropertyDetails.styled'
import { Link } from '../../../routes'
import * as S from './HelpfulLinks.styled'
import IconArrowRight from '@icons/ArrowRight'

const HelpfulLinks = ({ helpfulLinks }) => {
  if (helpfulLinks.length === 0) return null

  return (
    <Detail>
      <DetailHeading>Helpful links</DetailHeading>
      <S.HelpfulLinks>
        {helpfulLinks.map((it, key) => (
          <Link
            route={it.link}
            target={it.target}
            passHref
            key={`helpfulLinks-${key}`}
          >
            <Button
              border
              color='secondary'
              startIcon={it.type === 'document' ? <IconFile /> : null}
              endIcon={it.type === 'document' ? null : <IconArrowRight />}
            >
              {it.label || 'Visit link'}
            </Button>
          </Link>
        ))}
      </S.HelpfulLinks>
    </Detail>
  )
}

export default HelpfulLinks
