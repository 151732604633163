const IconCarBig = (props) => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18.6212 18.5752H9.64062'
        stroke='currentColor'
        strokeWidth='1.12609'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M5.40983 18.5752H2.66406V16.8204C2.66406 14.5007 4.4855 12.6201 6.73111 12.6201H18.7089C22.3612 12.6201 25.3218 14.8038 25.3218 18.5752H22.6737'
        stroke='currentColor'
        strokeWidth='1.12609'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M7.43576 19.9125C8.65316 19.9125 9.64007 18.9256 9.64007 17.7082C9.64007 16.4908 8.65316 15.5039 7.43576 15.5039C6.21835 15.5039 5.23145 16.4908 5.23145 17.7082C5.23145 18.9256 6.21835 19.9125 7.43576 19.9125Z'
        stroke='currentColor'
        strokeWidth='1.12609'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M20.6467 19.9125C21.8641 19.9125 22.851 18.9256 22.851 17.7082C22.851 16.4908 21.8641 15.5039 20.6467 15.5039C19.4293 15.5039 18.4424 16.4908 18.4424 17.7082C18.4424 18.9256 19.4293 19.9125 20.6467 19.9125Z'
        stroke='currentColor'
        strokeWidth='1.12609'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M19.2647 12.7181L15.5589 8.61825C15.1751 8.17907 14.5952 7.92383 13.9815 7.92383H9.05015C8.24031 7.92383 7.50835 8.36769 7.18929 9.05179L5.93652 12.9424'
        stroke='currentColor'
        strokeWidth='1.12609'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M10.96 8.20508V12.244'
        stroke='currentColor'
        strokeWidth='0.750724'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconCarBig
