import styled from 'styled-components'
import { DEVICES } from '@constants/global'

export const HelpfulLinks = styled.div`
  @media ${DEVICES.tablet} {
    padding-bottom: 10px;
  }

  a {
    margin-right: 20px;
    margin-bottom: 18px;
    display: inline-block;
    vertical-align: middle;

    @media ${DEVICES.mobile} {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
