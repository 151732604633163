const IconAngleRight = props => {
  return (
    <svg
      width='5'
      height='10'
      viewBox='0 0 5 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 5.09096L0.780217 9.66239L0.105969 9.04001L3.75124 5.09096L0.105969 1.14191L0.780217 0.519531L5 5.09096Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconAngleRight
