const IconFile = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5.2056 11.833H10.3487V10.833H5.2056V11.833ZM5.2056 8.99967H10.3487V7.99967H5.2056V8.99967ZM3.62435 14.6663C3.36879 14.6663 3.14518 14.5663 2.95352 14.3663C2.76185 14.1663 2.66602 13.933 2.66602 13.6663V2.33301C2.66602 2.06634 2.76185 1.83301 2.95352 1.63301C3.14518 1.43301 3.36879 1.33301 3.62435 1.33301H9.39032L12.8882 4.98301V13.6663C12.8882 13.933 12.7924 14.1663 12.6007 14.3663C12.4091 14.5663 12.1855 14.6663 11.9299 14.6663H3.62435ZM8.91115 5.43301V2.33301H3.62435V13.6663H11.9299V5.43301H8.91115Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconFile
