import styled from 'styled-components'
import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'

export const Breadcrumbs = styled.div`
  padding: 30px 0;
  display: flex;

  @media ${DEVICES.mobile} {
    padding: 20px 0;
  }

  a,
  span {
    color: ${COLORS.grey[60]};
    text-decoration: none;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    font-size: 14px;
    line-height: 20px;

    &:hover {
      color: ${COLORS.primary};
    }

    svg {
      margin-left: 10px;
    }
  }

  span {
    color: ${COLORS.secondary};
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: ${COLORS.secondary};
    }
  }
`
