import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const MakeOfferButton = styled.div`  
  .OfferKit_make-an-offer {
    height: auto !important;
    border: none !important;
    border-radius: 20px !important;
    padding: 7px 16px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 1px !important;
    font-family: ${FONTS.regular} !important;
    text-transform: uppercase !important;
    display: inline-flex !important;
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer !important;
    transition: all 0.25s ease-in !important;
    border: 1px solid transparent !important;
    background: ${COLORS.primary} !important;
    color: ${COLORS.white} !important;
    width: 100%;
    margin-bottom: 15px !important;
    box-shadow: none !important;
    font-weight: unset !important;
    min-width: unset;

    &:hover {
      background: ${COLORS.secondary} !important;
      box-shadow: none !important;
      color: ${COLORS.white} !important;
      filter: brightness(1) !important;
    }
  }

  &#mobile-offer-button {
    font-family: ${FONTS.medium};
    color: ${COLORS.secondary};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    width: 85px;
    //margin: 0 6px;
    display: none;
    padding: 10px;

    &.active {
      display: flex;
      cursor: default;
      background-color: #FAF8F3;

      svg {
        color: ${COLORS.secondary} !important;
      }
    }

    svg {
      color: ${COLORS.primary};
      margin-bottom: 8px;
    }

    .OfferKit_make-an-offer {
      border: none !important;
      border-radius: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      font-family: ${FONTS.medium} !important;
      text-transform: unset !important;
      text-align: center !important;
      cursor: pointer !important;
      transition: all 0.25s ease-in !important;
      background: transparent !important;
      color: ${COLORS.secondary} !important;
      width: auto !important;

      flex-direction: column !important;

      &:hover {
        background-color: transparent !important;
        color: ${COLORS.secondary} !important;
      }
    }
  }
`
