import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { FONTS } from '@styles/fonts'

const NavItemStyle = css`
  font-family: ${FONTS.medium};
  color: ${COLORS.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  min-width: 85px;

  svg {
    color: ${COLORS.primary};
    margin-bottom: 8px;
  }
`

export const MobileNavItem = styled.div`
  ${NavItemStyle};
  padding: 10px;

  &:last-of-type {
    margin-right: 0;
  }

  &:first-of-type {
    margin-left: 0;
  }

  ${({ active }) =>
    active &&
    css`
      cursor: default;
      background-color: #FAF8F3;

      svg {
        color: ${COLORS.secondary} !important;
      }
    `}
`

export const MobileNav = styled.div`
  position: fixed;
  bottom: ${({ scrolled }) => (scrolled ? '0' : '-100%')};
  z-index: 100;
  width: 100%;
  background-color: ${COLORS.nature[95]};
  padding: 10px 16px;
  align-items: flex-end;
  display: none;

  a {
    ${NavItemStyle};
  }

  ${({ justifyBetween }) =>
    justifyBetween &&
    css`
      justify-content: space-between;
    `}

  transition: bottom 0.3s ease; /* Transition for smooth appearance */
  
  @media ${DEVICES.tablet} {
    display: flex;
  }
`;
